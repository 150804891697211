.esw-country-selector {
	border-bottom: 0.3125rem solid #ccc;
	width: 100%;
	z-index: 30;
}

.closeLandingPage {
	position: absolute;
	top: 0rem;
	right: 0.3125rem;
	font-size: 1.625rem;
}

@media screen and (min-width: 48rem) {
	.esw-country-selector {
		border-bottom: none;
		padding: 0;
		width: auto;
	}
}

.esw-country-selector .selector {
	display: none;
	margin-top: 0.5rem;
	padding-left: 2rem;
}

.esw-country-selector .selector.active {
	display: block;
}

@media screen and (min-width: 48rem) {
	.esw-country-selector .selector {
		background-color: #fff;
		border-radius: 0.1875rem;
		box-shadow: -0.0625rem 0.125rem 0.25rem rgba(0, 0, 0, .3);
		margin-top: 1rem;
		padding: 1rem;
	}
}

.esw-country-selector .current-country {
	cursor: pointer;
	padding: 0.8em 1rem;
}

.esw-country-selector .current-country.selector-active {
	border-bottom: 0.0625rem solid #ccc;
}

.esw-country-selector .current-country.selector-active .selector-icon {
	transform: rotate(270deg);
}

@media screen and (min-width: 48rem) {
	.esw-country-selector .current-country {
		padding: 0;
	}

	.esw-country-selector .current-country.selector-active {
		border-bottom: none;
		padding-bottom: 0;
		position: absolute;
		right: 0;
	}
}

.esw-country-selector .current-country .selector-icon {
	margin-right: 0.3rem;
}

@media screen and (min-width: 48rem) {
	.esw-country-selector .current-country .selector-icon {
		float: right;
		margin-right: 0;
		margin-left: 0.3rem;
		transform: rotate(90deg);
	}
}

.esw-country-selector .current-country .flag-icon {
	border: none;
}

.esw-country-selector .continent-name {
	margin-top: 0;
	margin-bottom: 0.5rem;
}

@media screen and (min-width: 48rem) {
	.esw-country-selector .continent-name {
		color: #000;
	}
}

.esw-country-selector .country {
	margin-bottom: 0.5rem;
}

.esw-country-selector .locale {
	border-right: 0.0625rem solid #ccc;
	display: inline-block;
	padding: 0 0.3rem;
	vertical-align: middle;
}

.esw-country-selector .locale:first-of-type {
	padding-left: 0;
}

.esw-country-selector .locale:last-of-type {
	border-right: none;
	padding-right: 0;
}

.esw-country-selector .flag-icon {
	border: 0.0625rem solid #ccc;
	margin-right: 0.3rem;
}

.selectors.headerDropdown .esw-country-selector {
	position: relative;
	top: 0;
	right: 0;
}

.selectors.headerDropdown .esw-country-selector .current-country.selector-active {
	position: relative;
}

.selectors.headerDropdown .esw-country-selector .selector.active {
	position: absolute;
	width: auto;
}

.selectors.headerDropdown .esw-country-selector .selector.active a {
	margin: 0.3125rem 0rem;
	display: block;
}

.selectors * {
	outline: none;
}

.selectors {
	width: 21.875rem;
	margin: 0 auto;
	display: block;
}

.selectors .esw-country-selector {
	width: 14.3125rem;
	display: inline-block;
	border-bottom: none;
}

.selectors .esw-country-selector .selector.active {
	background-color: #fff;
	border-radius: 0.1875rem;
	box-shadow: -0.0625rem 0.125rem 0.25rem rgba(0, 0, 0, 0.3);
	margin-top: 1rem;
	padding: 0 1rem;
}

@media screen and (min-width: 48rem) {
	.selectors .esw-country-selector:nth-child(2) {
		width: 6.25rem;
	}

	.selectors .esw-country-selector:nth-child(3) {
		width: 4.375rem;
	}
}

.selectors .esw-country-selector select {
	max-width: 7.5625rem;
}

.selectors .esw-country-selector select option {
	color: black;
}

.selectors.footerDropdown .footer-select {
	position: relative;
	margin-top: 1.5625rem;
	float: left;
}

.selectors.footerDropdown .footer-select .current-country {
	color: white;
}

.selectors.footerDropdown .footer-select .current-country.selector-active {
	position: relative;
}

.selectors.footerDropdown .footer-select .selector.active {
	position: absolute;
	width: auto;
	bottom: 2.1875rem;
}

.selectors.footerDropdown .footer-select .selector.active a {
	margin: 0.3125rem 0rem;
	display: block;
	color: black;
}

@media screen and (min-width: 48rem) {
	.selectors.footerDropdown .footer-select:nth-child(1) {
		width: 9.0625rem;
	}
}

.selectors.headerDropdown {
	display: inline-block;
	width: 14.3125rem;
	cursor: pointer;
}

.selectors.headerDropdown .esw-country-selector {
	position: relative;
	top: 0;
	right: 0;
}

.selectors.headerDropdown .esw-country-selector.active {
	position: relative;
}

@media screen and (max-width: 47.9375rem) {
	.selectors.headerDropdown {
		position: relative;
		top: 0;
		right: -0.625rem;
	}

	.selectors.footerDropdown {
		display: none;
	}
}

.selectors.headerDropdown div select {
	border: 0;
	background: no-repeat;
}

.selectors.footerDropdown {
	margin-left: 0rem;
	margin-top: 1.875rem;
	cursor: pointer;
}

.selectors.footerDropdown .selectLanguage {
	z-index: 3;
}

.selectors.footerDropdown .selectCurrency {
	z-index: 2;
}

.selectors.footerDropdown .dropdown-menu {
	max-height: 10rem;
	overflow-y: scroll;
}

@media screen and (max-width: 26.5625rem) {
	.selectors {
		width: 90%;
	}

	.selectors>div {
		width: 100%;
		display: block;
	}
}

.modalBg {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-color: black;
	z-index: 100;
	opacity: 0.8;
}

.eswModal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1000;
	padding: 0.9375rem 1.25rem;
	background: whitesmoke;
	border: 0.0625rem solid black;
	min-width: 18.75rem;
	color: black;
	height: auto;
	width: auto;
	max-width: 43.75rem;
}

.eswModal .eswModal-content .esw-header-wrapper {
	text-align: center;
	padding-bottom: 0.9375rem;
	max-width: 20rem;
	margin: 0.9375rem auto 0 auto;
}

.eswModal .eswModal-content .esw-header-wrapper h1 {
	font-size: 1.5625rem;
	margin-top: 1.5625rem;
}

.eswModal .eswModal-content .esw-header-wrapper i.flag-icon {
	line-height: 2em;
	width: 2.1875rem;
}

.eswModal .eswModal-content .esw-header-wrapper p {
	font-size: 0.875rem;
}

.eswModal .selectors {
	margin: 0.9375rem auto 0.9375rem auto;
	width: 23.125rem;
}

@media screen and (max-width: 47.9375rem) {
	.eswModal .selectors {
		width: 100%;
		margin-top: 0;
	}
}

.eswModal .selectors .esw-country-selector {
	width: 100%;
	position: relative;
	top: 0;
	right: 0;
}

.eswModal .selectors .esw-country-selector .current-country {
	border: 0.0625rem solid gray;
	padding: 0.375rem;
	width: 12.1875rem;
}

.eswModal .selectors .esw-country-selector.selectCurrency .select-field.disabled {
	cursor: not-allowed;
	color: gray;
}

.eswModal .selectors .esw-country-selector.selectCurrency .select-field .current-country.disabled {
	pointer-events: none;
}

@media screen and (max-width: 47.9375rem) {
	.eswModal .selectors .esw-country-selector .current-country {
		width: 100%;
	}
}

.eswModal .selectors .esw-country-selector .current-country.selector-active {
	position: relative;
}

.eswModal .selectors .esw-country-selector label {
	float: left;
	padding: 0.3125rem 0rem 0rem;
}

.eswModal .selectors .esw-country-selector .select-field {
	float: right;
}

.eswModal .selectors .esw-country-selector .selector.active {
	position: absolute;
	width: auto;
	top: 1.25rem;
	max-height: 12.5rem;
	min-width: 12.1875rem;
	overflow-y: scroll;
}

@media screen and (max-width: 47.9375rem) {
	.eswModal .selectors .esw-country-selector .selector.active {
		width: 100%;
		top: 3.70rem;
	}
}

.eswModal .selectors .esw-country-selector .selector.active a {
	margin: 0.3125rem 0rem;
	display: block;
}

.eswModal .selectors .esw-country-selector.selectLanguage {
	z-index: 3;
}

.eswModal .selectors .esw-country-selector.selectCurrency {
	z-index: 2;
}

@media screen and (max-width: 47.9375rem) {
	.selectors {
		padding-top: 0;
		flex-direction: column;
	}

	.selectors div {
		width: 100%;
	}
}

.product-not-available-msg-cart {
	padding-top: 0.625rem;
	color: #900;
}

.menu-item .esw-country-selector .btn {
	padding-left: 0.3rem;
}

.eshopworld-loader {
	position: fixed;
	left: 0;
	top: 0;
	background: #fff 50% 40% url(../images/loading-small.gif) no-repeat;
	width: 100%;
	height: 100%;
	opacity: 0.7;
	z-index: 10000;
}